
import { } from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';
import { mapGetters } from "vuex";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
const ConceptPrivacyPolicyProps = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
});
@Component({
  components: {
    Translations,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  },
  mixins: [translations],
})
export default class ConceptMarketing extends ConceptPrivacyPolicyProps {
  translate!: Function;
  public getLocale: any;
}
